import "./register.css"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"
import TextField from "@mui/material/TextField"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "../../common/Button"
import logo from "../../assets/logo1.svg"
import { LOADER_OVERRIDE } from "../../utils/constants"
import Api from "../../api/api"
import { Notifications } from "../../helper/notifications"

function RegisterRestaurantSection() {
  const history = useHistory()
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    storeName: "",
    email: "",
    password: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    fname: "",
    lname: "",
    phone: "",
    role:"1",
    type:"user",
    businessType: "", //home-kitchen, food-truck, restaurant, others
    explainBusiness: "", //if others, then explain
  })

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)

    const res = await Api.register(data)
    if (res.status === 201) {
      Notifications("success", res.data.message)
      history.push("/verify")
    }
    setIsDisable(false)
  }

  return (
    <>
      <div className="loginWrapper">
        <form
          onSubmit={submitHandle}
          className="rrWrapper br10"
        >
          <p className="loginLogoWrapper text-center">
            <img
              src={logo}
              alt=""
              className="loginLogoImg"
            />
          </p>
          <br />
          <h2 className="loginHead text-center fw-bold">Register Restaurant</h2>
          <br />

          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic1"
                  label="Store Name"
                  variant="outlined"
                  name="storeName"
                  type="text"
                  value={data.storeName}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic2"
                  label="Address"
                  variant="outlined"
                  name="address"
                  type="text"
                  value={data.address}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic3"
                  label="City"
                  variant="outlined"
                  name="city"
                  type="text"
                  value={data.city}
                  onChange={inputHandle}
                  required
                />
              </Col>
              <br />
              <br />
              <br />
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic4"
                  label="State"
                  variant="outlined"
                  name="state"
                  type="text"
                  value={data.state}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic5"
                  label="Zip Code"
                  variant="outlined"
                  name="zipcode"
                  type="number"
                  value={data.zipcode}
                  onChange={inputHandle}
                  required
                />
              </Col>
              <br />
              <br />
              <br />
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic6"
                  label="Country"
                  variant="outlined"
                  name="country"
                  type="text"
                  value={data.country}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          {/* <Container fluid>
            <Row>
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic7"
                  label="First Name"
                  variant="outlined"
                />
              </Col>
              <br />
              <br />
              <br />
              <Col
                lg={6}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic8"
                  label="Last Name"
                  variant="outlined"
                />
              </Col>
            </Row>
          </Container>
          <br /> */}
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic9"
                  type="email"
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={data.email}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic10"
                  type="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  value={data.password}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <TextField
                  className="width100P"
                  color="warning"
                  id="outlined-basic11"
                  label="Phone Number"
                  variant="outlined"
                  name="phone"
                  type="number"
                  value={data.phone}
                  onChange={inputHandle}
                  required
                />
              </Col>
            </Row>
          </Container>
          <br />
          <Container fluid>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Business Type
                  </InputLabel>
                  <Select
                    color="warning"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="businessType"
                    value={data.businessType}
                    onChange={inputHandle}
                    required
                  >
                    <MenuItem value="home-kitchen">Cloud/Home Kitchen</MenuItem>
                    <MenuItem value="food-truck">Food Trucks</MenuItem>
                    <MenuItem value="restaurant">Restaurant</MenuItem>
                    <MenuItem value="others">Others (specify)</MenuItem>
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Container>
          <br />
          {data.businessType === "others" ? (
            <>
              <Container fluid>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <TextField
                      className="width100P"
                      color="warning"
                      id="outlined-basic"
                      label="Explain Business Type"
                      variant="outlined"
                      name="explainBusiness"
                      value={data.explainBusiness}
                      onChange={inputHandle}
                      required
                    />
                  </Col>
                </Row>
              </Container>
              <br />
            </>
          ) : (
            ""
          )}
          <p className="text-center">
            By clicking "Submit," you agree to{" "}
            <span className="cTheme pointer">
              Mealsbus Terms and Conditions
            </span>{" "}
            and acknowledge you have read the{" "}
            <span className="cTheme pointer">Privacy Policy's</span>.
            <Link to={"/register"}></Link>
          </p>
          <br />
          <p className="text-center">
            <Button disabled={isDisable}>
              {!isDisable ? (
                "Submit"
              ) : (
                <ClipLoader
                  color="#FFFFFF"
                  loading={true}
                  cssOverride={LOADER_OVERRIDE}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </p>
          <br />
          <p className="text-center">
            Already have an account ?{" "}
            <Link to={"/login"}>
              <span className="cTheme pointer">Login</span>
            </Link>
          </p>
        </form>
      </div>
    </>
  )
}

export default RegisterRestaurantSection
