import "../components/home/home.css"
import { useCallback, useLayoutEffect, useState } from "react"
import Panel from "../layout/panel/panel"
import Loader from "../components/loader/loader"
import { Container, Row, Col } from "react-bootstrap"

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import Api from "../api/api"
import { useSelector } from "react-redux"

const Home = () => {
  const seller = useSelector((state) => state.seller)
  const [isLoading, setIsLoading] = useState(true)
  const [year, setYear] = useState(new Date().getFullYear())
  const [data, setData] = useState([])
  const [completed, setCompleted] = useState()
  const [cancelled, setCancelled] = useState()

  const getData = useCallback(
    async (id, year) => {
      setIsLoading(true)
      try {
        const res = await Api.getReports(id, year)
        if (res.status === 200) {
          setData(res.data.data)
          setCompleted(
            res.data.data?.ordersByStatus?.find(
              (item) => item.status === "completed",
            ),
          )
          setCancelled(
            res.data.data?.ordersByStatus?.find(
              (item) => item.status === "cancelled",
            ),
          )
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
      }
    },
    [setData],
  )

  useLayoutEffect(() => {
    getData(seller.id, year)
  }, [])

  return (
    //panel_wrapper for page
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Panel>
          <div className="c_card">
            <div>
              <label htmlFor="year">Select Year:</label>
              <select
                id="year"
                style={{ margin: "0 0 16px 16px" }}
                value={year}
                onChange={(e) => [
                  setYear(e.target.value),
                  getData(seller.id, e.target.value),
                ]}
              >
                {data?.years.length <= 0 ? (
                  <option value={year}>{year}</option>
                ) : (
                  data?.years?.map((item, index) => (
                    <option
                      key={index}
                      value={item.year}
                    >
                      {item.year}
                    </option>
                  ))
                )}
              </select>
            </div>

            <Container fluid>
              <Row style={{ rowGap: 16 }}>
                <Col
                  lg={3}
                  md={4}
                  xs={12}
                >
                  <div className="bCardTheme p10 br10 cTheme">
                    <p>TOTAL ORDERS</p>
                    <h2>{data?.orderCounts}</h2>
                  </div>
                </Col>
                <Col
                  lg={3}
                  md={4}
                  xs={12}
                >
                  <div className="bCardTheme p10 br10 cTheme">
                    <p>TOTAL SALES</p>
                    <h2>
                      ${data?.orderSales?.sales ? data?.orderSales?.sales : 0}
                    </h2>
                  </div>
                </Col>
                <Col
                  lg={3}
                  md={4}
                  xs={12}
                >
                  <div className="bCardTheme p10 br10 cTheme">
                    <p>COMPLETED ORDERS</p>
                    <h2>{completed ? completed?.orders : 0}</h2>
                  </div>
                </Col>
                <Col
                  lg={3}
                  md={4}
                  xs={12}
                >
                  <div className="bCardTheme p10 br10 cTheme">
                    <p>CANCELLED ORDERS</p>
                    <h2>{cancelled ? cancelled?.orders : 0}</h2>
                  </div>
                </Col>
              </Row>
            </Container>

            <br />
            <br />
            <br />

            <Container fluid>
              <Row>
                <Col>
                  <div style={{ width: "100%", height: "300px" }}>
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                    >
                      <LineChart data={data.graphData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="orders"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="sales"
                          stroke="#f4661c"
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Panel>
      )}
    </>
  )
}

export default Home
