import "./profile.css"
import { useState, useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { CountryDropdown } from "react-country-region-selector"
import ClipLoader from "react-spinners/ClipLoader"
import Api from "../../api/api"
import Button from "../../common/Button"
import camera from "../../assets/icons/camera.svg"
import { LOADER_OVERRIDE } from "../../utils/constants"
import { Notifications } from "../../helper/notifications"
import Loader from "../../components/loader/loader"
import { getMe } from "../../store/slices/sellerSlice"

const Profile = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [data, setData] = useState({
    avatar: "",
    avatarURL: "",
    banner: "",
    bannerURL: "",
    storeName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  })

  const submitHandle = async (e) => {
    e.preventDefault()

    setIsDisable(true)
    const res = await Api.updateProfile(data)
    if (res.status === 200) {
      Notifications("success", res.data.message)
      dispatch(getMe())
    }
    setIsDisable(false)
  }

  const setAvatarHandle = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          avatar: event.target.result,
          avatarURL: event.target.result,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const setBannerHandle = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          banner: event.target.result,
          bannerURL: event.target.result,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const inputHandle = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  useLayoutEffect(() => {
    async function getMeProfile() {
      const res = await Api.getMeProfile()
      if (res.status === 200) {
        setData({
          avatarURL: res.data.data.avatar,
          bannerURL: res.data.data.banner,
          storeName: res.data.data.storeName,
          email: res.data.data.email,
          phone: res.data.data.phone,
          address: res.data.data.address,
          city: res.data.data.city,
          country: res.data.data.country,
          state: res.data.data.state,
          zipcode: res.data.data.zipcode,
        })
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      }
    }

    getMeProfile()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="profile_page_cont">
          <div className="c_card">
            <div className="title_3">Profile Setting</div>
            <form
              onSubmit={submitHandle}
              className="profile_form pt"
            >
              <div className="profile_cover_wrapper">
                <img
                  src={data?.bannerURL}
                  alt="Cover Photo"
                />
              </div>
              <Picture
                image={data?.avatarURL}
                setImageHandle={setAvatarHandle}
              />
              <br />
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="storeName"
                    value={data.storeName}
                    onChange={inputHandle}
                    placeholder="Store Name"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="email"
                    value={data.email}
                    onChange={inputHandle}
                    placeholder="Email"
                    required
                    disabled
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="number"
                    name="phone"
                    value={data.phone}
                    onChange={inputHandle}
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="address"
                    value={data.address}
                    onChange={inputHandle}
                    placeholder="Address"
                    required
                  />
                </div>
              </div>

              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="city"
                    value={data.city}
                    onChange={inputHandle}
                    placeholder="City"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="state"
                    value={data.state}
                    onChange={inputHandle}
                    placeholder="State"
                    required
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <input
                    type="number"
                    name="zipcode"
                    value={data.zipcode}
                    onChange={inputHandle}
                    placeholder="Zip Code"
                    required
                  />
                </div>
                <div className="input_wrapper">
                  <CountryDropdown
                    name="country"
                    value={data?.country}
                    onChange={(val) =>
                      setData((prev) => ({
                        ...prev,
                        country: val,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="input_wrapper">
                  <label htmlFor="">Cover Photo</label>
                  <input
                    type="file"
                    name="banner"
                    onChange={setBannerHandle}
                    accept="image/*"
                  />
                </div>
              </div>
              <Button
                disabled={isDisable}
                type="submit"
                margin="0 0 8px 0"
              >
                {!isDisable ? (
                  "Update"
                ) : (
                  <ClipLoader
                    color="#FFFFFF"
                    loading={true}
                    cssOverride={LOADER_OVERRIDE}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </Button>
              {/* <div className="profile_link">
            <a>
              <img
                src={deleteIcon}
                alt="Delete Account"
              />
              <span>Delete Account</span>
            </a>
          </div> */}
            </form>
          </div>
        </div>
      )}
    </>
  )
}

const Picture = ({ image, setImageHandle }) => {
  return (
    <div className="profile_picture">
      <label htmlFor="avatar">
        <img
          src={image}
          alt="avatar"
        />
        <span>
          <img
            src={camera}
            alt=""
          />
        </span>
      </label>
      <input
        type="file"
        id="avatar"
        name="avatar"
        accept="image/*"
        onChange={setImageHandle}
      />
    </div>
  )
}

export default Profile
