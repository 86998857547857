export const LINKS = [
  {
    to: "/",
    title: "Dashboard",
  },
  {
    to: "/orders",
    title: "Orders",
  },
  {
    to: "/users",
    title: "Users",
  },
  {
    to: "/categories",
    title: "Categories",
  },
  {
    to: "/products",
    title: "Products",
  },
  {
    to: "/reviews",
    title: "Reviews",
  },
  {
    to: "/profile",
    title: "Profile Settings",
  },
  {
    to: "/account",
    title: "Account Settings",
  },
  {
    to: "/password",
    title: "Password Settings",
  },
]
